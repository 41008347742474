import React, { useState } from 'react';
import { Nav, Navbar, Button, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { requestHandlerFunction } from "../lib/RequestHandler";
import { BASE_URL, NEW_BASE_URL } from "../lib/common";
import Logo from '../assets/icons/vidaLogo.png';
import "./css/Header.css";

const Header = () => {
    const location = useLocation();
    const [toggleAlert, setToggleAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('green');

    const alertStyle = {
        position: "absolute",
        right: "0",
        left: "0",
        zIndex: "10",
        backgroundColor: alertColor
    }

    function Alerts() {
        return (
            <>
                {/* border: 2px solid gray;
  max-width: 500px;
  height: 50px;
  text-align: center;
  position: absolute;
  top: 5px;
  margin: auto;
  right: 0;
  left: 0; */}
                {/* {toggleAlert && <div className="alert" style={{ backgroundColor: alertColor }}>{alertMessage}</div>} */}
                {toggleAlert && <div className="alert" style={alertStyle}>{alertMessage}</div>}
            </>
        )
    }

    function showAlert(message, color, time = 3000) {
        setAlertMessage(message);
        setAlertColor(color);
        setToggleAlert(true);
        setTimeout(function () { setToggleAlert(false) }, time)
    }

    function reloadPortal() {
        const url = BASE_URL + "sbv/support/reload";
        requestHandlerFunction(url, "reload", "get", "string").then(function (resp) {
            console.log(resp);
            showAlert('SUCCESS: Support portal reloaded', 'lightgreen');
        }).catch(function (error) { console.log(error) });
    }

    function reloadShopifyPlugin() {
        const url = NEW_BASE_URL + "reload";
        requestHandlerFunction(url, "reload", "get", "string").then(function (resp) {
            console.log(resp);
            showAlert('SUCCESS: Shopify plugin reloaded', 'lightgreen');
        }).catch(function (error) { console.log(error) });
    }

    function reloadAnnotation() {
        const url = BASE_URL + `reloadDressesInfo`;
        fetch(url, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((data) => {
                    showAlert(`SUCCESS: Annotation data reloaded`, 'lightgreen');
                    console.log("SUCCESS: Annotation data reloaded");
            })
            .catch((error) => {
                showAlert(`Error While reloading Annotation Data ${error}`, 'red');
                console.log('Error While reloading Annotation Data', error);
            });
    }

    return (
        <>
            <Navbar className="navbar navbar-dark bg-dark" collapseOnSelect expand="lg">
                <Container>
                    <Navbar.Brand >
                        <img
                            alt=""
                            src={Logo}
                            width="50"
                            height="40"
                            className="d-inline-block align-top"
                            style={{ backgroundColor: "white", marginLeft: "10px", borderRadius: "5px", padding: "0px 5px" }}
                        />
                    </Navbar.Brand>
                    <Navbar.Brand >INTERNAL SBV PORTAL</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/" className={location.pathname === '/' ? 'active' : ''}>SupportPortal</Nav.Link>
                            <Nav.Link as={Link} to="/ux-analytics" className={location.pathname === '/ux-analytics' ? 'active' : ''}>UxAnalytics</Nav.Link>
                            <Nav.Link as={Link} to="/chatbot" className={location.pathname === '/chatbot' ? 'active' : ''}>AIStyling</Nav.Link>
                            <Nav.Link as={Link} to="/annotate" className={location.pathname.startsWith("/annotate") ? 'active' : ''}>Annotation</Nav.Link>
                            <Nav.Link as={Link} to="/colorAnnotate" className={location.pathname.startsWith("/colorAnnotate") ? 'active' : ''}>ColorAnnotation</Nav.Link>
                            <Nav.Link as={Link} to="/configuration" className={location.pathname === '/configuration' ? 'active' : ''}>Configuration</Nav.Link>
                        </Nav>
                        <div className="ml-auto">
                            <Button variant="secondary" size="sm" className="m-1" onClick={reloadPortal}>Reload Support</Button>
                            <Button variant="secondary" size="sm" className="m-1" onClick={reloadShopifyPlugin}>Reload Shopify</Button>
                            <Button variant="secondary" size="sm" className="m-1" onClick={reloadAnnotation}>Reload Ann</Button>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Alerts />
        </>
    )
}

export default Header;