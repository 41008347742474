import React, { useState } from 'react'
import "./css/Configuration.css";
import { Button } from 'react-bootstrap';
import { Eye, ChevronDown, Plus, Edit, ChevronUp, EyeOff } from 'lucide-react';

const options1 = ['Pear', 'Rectangle', 'Apple', 'Inverted Triangle', 'Hourglass'];
const options2 = ['Casual', 'Party', 'Office'];
const options3 = ['Look Taller', 'Hide Big Bottom', 'Broaden Shoulder'];
const options4 = ['Style Board/Add your Own', 'Model with Relatable Body/Add your Own', 'Own Image/Avatar/Add your Own'];
const options5 = ['1000-2000/Add your own', '2000-3000/Add your own', '3000-4000/Add your own', '4000 and above/Add your own'];

export default function Configuration() {

    const [collapse1, setCollapse1] = useState(true);
    const [collapse2, setCollapse2] = useState(true);
    const [collapse3, setCollapse3] = useState(true);

    const [hidden1, setHidden1] = useState(false);
    const [hidden2, setHidden2] = useState(false);
    const [hidden3, setHidden3] = useState(false);

  return (
    <div className='config-container'>
        <div className='config-header'>
            <span>Question Template</span>
            <Button variant="dark" size="sm">Save</Button>
        </div>
        
        <div className='config-questions'>

            <div className='qo'>
                <div className='question'>
                    <span>1. Select your body type</span>
                    <div className='actions'>
                        {!hidden1 ? <Eye className='pointer' onClick={() => setHidden1(prev => !prev)}/> : <EyeOff className='pointer' onClick={() => setHidden1(prev => !prev)}/>}
                        {collapse1 ? <ChevronDown className='pointer' onClick={() => setCollapse1(prev => !prev)}/> : <ChevronUp className='pointer' onClick={() => setCollapse1(prev => !prev)}/>}
                    </div>
                </div>
                <div className='options-container'>
                    <div className='options-add-container'>
                            {!collapse1 && <div className='options'>
                            {options1.map((value) => (
                                        <div className='option'>
                                            <span>{value}</span>
                                            <div className='actions'>
                                                <Edit size={15}/>
                                                <Plus size={15} className='cross'/>
                                            </div>
                                        </div>
                                    ))}
                        </div>}
                    </div>
                
                </div>
            </div>

            <div className='qo'>
                <div className='question'>
                    <span>2. What is the Occasion? </span>
                    <div className='actions'>
                    {!hidden2 ? <Eye className='pointer' onClick={() => setHidden2(prev => !prev)}/> : <EyeOff className='pointer' onClick={() => setHidden2(prev => !prev)}/>}
                        {collapse2 ? <ChevronDown className='pointer' onClick={() => setCollapse2(prev => !prev)}/> : <ChevronUp className='pointer' onClick={() => setCollapse2(prev => !prev)}/>}
                    </div>
                </div>
                {!collapse2 &&<div className='options'>
                    {options2.map((value) => (
                                <div className='option'>
                                    <span>{value}</span>
                                    <div className='actions'>
                                        <Edit size={15}/>
                                        <Plus size={15} className='cross'/>
                                    </div>
                                </div>
                            ))}
                </div>}
            </div>

            <div className='qo'>
                <div className='question'>
                    <span>3. What is your Goal?</span>
                    <div className='actions'>
                    {!hidden3 ? <Eye className='pointer' onClick={() => setHidden3(prev => !prev)}/> : <EyeOff className='pointer' onClick={() => setHidden3(prev => !prev)}/>}
                        {collapse3 ? <ChevronDown className='pointer' onClick={() => setCollapse3(prev => !prev)}/> : <ChevronUp className='pointer' onClick={() => setCollapse3(prev => !prev)}/>}
                    </div>
                </div>
                {!collapse3 && <div className='options'>
                    {options3.map((value) => (
                                <div className='option'>
                                    <span>{value}</span>
                                    <div className='actions'>
                                        <Edit size={15}/>
                                        <Plus size={15} className='cross'/>
                                    </div>
                                </div>
                            ))}
                </div>}
            </div>

            <div className='qo'>
                <div className='question'>
                    <span>4. Select One Custom Question</span>
                    <div className='add-q'>
                        <span>Add Question</span>
                        <Plus size={20}/>
                    </div>
                </div>
                <div className='options-2'>
                    <div className='question-2'>
                        <span>i.  How would you prefer to see the Curated Styles?</span>
                            <div className='actions'>
                                <Eye size={15}/>
                                <Edit size={15}/>
                            </div>
                    </div>

                    <div className='options-container'>
                        <div className='option-add-container'>
                            {options4.map((value) => (
                                <div className='option'>
                                    <span>{value}</span>
                                    <div className='actions'>
                                        <Edit size={15}/>
                                        <Plus size={15} className='cross'/>
                                    </div>
                                </div>
                            ))}

                            
                        </div>
                        <Plus size={20}/>
                    </div>
                </div>
            </div>

            <div className='qo2'>
                <div className='options-2'>
                    <div className='question-2'>
                        <span>ii. Select your budget range?</span>
                            <div className='actions'>
                                <Eye size={15}/>
                                <Edit size={15}/>
                            </div>
                    </div>

                    <div className='options-container'>
                        <div className='option-add-container'>
                            {options5.map((value) => (
                                <div className='option'>
                                    <span>{value}</span>
                                    <div className='actions'>
                                        <Edit size={15}/>
                                        <Plus size={15} className='cross'/>
                                    </div>
                                </div>
                            ))}

                            
                        </div>
                        <Plus size={20}/>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  )
}
