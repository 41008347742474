import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import ShopifyPortal from './components/ShopifyPortal';
import ShopifyPortal2 from './components/ShopifyPortal2';
import UxAnalytics from './components/UxAnalytics';
import Chatbot from './components/Chatbot';
import Header from './components/Header';
import Annotate from './components/Annotate';
import AnnotateCard from './components/AnnotateCard';
import ColorAnnotateCard from './components/ColorAnnotateCard';
import ImgAnaAnnotate from './components/ImgAnaAnnotate';
import Configuration from './components/Configuration';

function App() {

  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<ShopifyPortal2 />} />
          <Route exact path="/ux-analytics" element={<UxAnalytics />} />
          <Route exact path="/chatbot" element={<Chatbot />} />
          <Route exact path="/annotate" element={<Annotate />} />
          <Route exact path="/colorAnnotate" element={<Annotate />} />
          <Route exact path="/annotate/:store" element={<AnnotateCard />} />
          <Route exact path="/annotate/:store/:ph" element={<ImgAnaAnnotate />} />
          <Route exact path="/colorAnnotate/:store" element={<ColorAnnotateCard />} />
          <Route exact path="/configuration" element={<Configuration />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
